@import 'antd/lib/notification/style/index.css';
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:400");

@font-face {
    font-family: 'F37Ginger-Regular';
    src: url("./assets/fonts/F37Ginger-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'F37Ginger-Bold';
    src: url("./assets/fonts/f37ginger-bold-webfont.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ant-notification {
    z-index: 1000000 !important;
}

@media (max-width: 425px) {
    body.lock-scroll {
        overflow: hidden;
    }   
}
