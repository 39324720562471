@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400);
@font-face {
    font-family: 'F37Ginger-Regular';
    src: url(/static/media/F37Ginger-Regular.164d3a55.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'F37Ginger-Bold';
    src: url(/static/media/f37ginger-bold-webfont.7562f09f.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ant-notification {
    z-index: 1000000 !important;
}

@media (max-width: 425px) {
    body.lock-scroll {
        overflow: hidden;
    }   
}

